import $ from 'jquery';

$('[data-read-lines]').each(function () {
    const $this = $(this);
    const $text = $this.find('p');
    const button = $this.find('[data-read-more-button]');
    const lines = $this.data('read-lines');

    const style = window.getComputedStyle($this[0], null);
    const lineHeight = parseInt(style.getPropertyValue("line-height"));

    const maxHeight = (lines + 1) * lineHeight + "px";
    $text.css('max-height', maxHeight);

    button.on('click', function (e) {
        e.preventDefault();
        if ($text.attr('aria-expanded') === 'false') {
            $text.css('max-height', '9999px');
            $text.attr('aria-expanded', 'true');
        }
        else {
            $text.css('max-height', maxHeight);
            $text.attr('aria-expanded', 'false');
        }
        const previousText = button.text();
        $(this).text($(this).attr('data-text'));
        $(this).attr('data-text', previousText);
        $(this).blur();
    });
});