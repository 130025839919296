import egumaAlgolia from "egumaAlgolia";
import algoliasearch from "algoliasearch";
import { autocomplete, getAlgoliaResults } from "@algolia/autocomplete-js";
import $ from "jquery";
import "@algolia/autocomplete-theme-classic/dist/theme.css";

if (egumaAlgolia.isEsurprise) {
  const searchClient = algoliasearch(
    egumaAlgolia.algoliaApplicationId,
    egumaAlgolia.algoliaApiKey
  );

  autocomplete({
    container: "#search-container",
    detachedMediaQuery: "none",
    placeholder: egumaAlgolia.algoliaSerachPlaceholder,
    initialState: {
      query: new URLSearchParams(window.location.search).get("q") || "",
    },
    getSources() {
      return [
        {
          sourceId: egumaAlgolia.algoliaVoucherIndex,
          getItems({ query, setContext }) {
            if (query.length >= 2) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: egumaAlgolia.algoliaVoucherIndex,
                    restrictSearchableAttributes: [
                      "name." + egumaAlgolia.langId,
                      "description." + egumaAlgolia.langId,
                      "city",
                      "category." + egumaAlgolia.langId,
                    ],
                    query,
                    params: {
                      hitsPerPage: 6,
                    },
                  },
                ],
                transformResponse({ results, hits }) {
                  setContext({
                    counter: results[0].nbHits,
                    query: results[0].query,
                  });

                  return hits;
                },
              });
            } else {
              return [];
            }
          },
          templates: {
            item({ item, html }) {
              return html`<a href="${item.url[egumaAlgolia.langId]}">
                <div class="algolia-wrapper">
                  <div class="algolia-result-image">
                    <img src="${item.image}" />
                  </div>
                  <div class="algolia-result-description">
                    <div class="algolia-result-title">
                      ${item.name[egumaAlgolia.langId]}
                    </div>
                    <div class="algolia-result-place">
                      ${item.city}, ${item.canton}
                    </div>
                    <div>${item.price[egumaAlgolia.langId]}</div>
                  </div>
                </div>
              </a>`;
            },
            footer({ state, html }) {
              return html`<a
                class="algolia-footer"
                href="/${egumaAlgolia.langId}/search?q=${state.context.query}"
                >${egumaAlgolia.algoliaShowHints.replace(
                  "(Count)",
                  state.context.counter
                )}</a
              >`;
            },
          },
          onSelect: function (event) {
            window.location.href = event.item.url[egumaAlgolia.langId];
          },
        },
      ];
    },
  });

  $(document).mouseup(function (e) {
    var container = $("#search-container-wrapper");

    // if the target of the click isn't the container nor a descendant of the container
    if (
      !container.is(e.target) &&
      container.has(e.target).length === 0 &&
      window.location.href.indexOf("search") === -1
    ) {
      container.attr("hidden", true);
    }
  });

  $(function () {
    $("#search-icon").on("click", function () {
      if ($("#search-container-wrapper").attr("hidden")) {
        $("#search-container-wrapper").attr("hidden", false);
        $(".aa-Input").focus();
      } else {
        $("#search-container-wrapper").attr("hidden", true);
      }
    });

    function search() {
      window.location.href =
        "/" + egumaAlgolia.langId + "/search?q=" + $(".aa-Input").val();
    }

    if (window.location.href.indexOf("search") > -1) {
      $("#search-container-wrapper").show();
    }

    $(".search-hero-button").on("click", function (e) {
      search();
      return false;
    });

    $(".aa-Input").keypress(function (e) {
      if (e.which === 13) {
        search();
        return false;
      }
    });
  });
}
