import $ from 'jquery';

$(document).ready(function () {
    $(document).on('click', '.ajax-loading', function (e) {
        e.preventDefault();
        const href = $(this).attr('href');
        const target = $(this).data('target');
        const targetElement = $(target);
        const slug = $(this).data('slug');
        const loaderElement = $('.product-list-loader');

        targetElement.attr('aria-live', 'polite');
        loaderElement.fadeIn();

        $.ajax({
            url: href,
            method: 'GET',
            success: function (response) {
                let targetResponse = $(target, response).children();
                if (targetResponse.length === 0) {
                    targetResponse = "<div class='col'>Keine Angebote in dieser Kategorie</div>";
                }
                targetElement.hide().html(targetResponse).fadeIn('slow');
                if (window.scrollY > $(".product-list").offset().top) {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(".product-list").offset().top - 130
                    }, 1000);
                }

                loaderElement.fadeOut();
                const url = new URL(window.location);
                if(slug === '') {
                    url.searchParams.delete('filter');
                } else {
                    url.searchParams.set('filter', slug);
                }

                window.history.replaceState({}, '', url);
                $('.filter').trigger("filter-loading-completed"); // Event for Wishlist click handler
            }
        });
    });

    if (window.location.search !== '') {
        const url = new URL(window.location);
        const filter = url.searchParams.get('filter');
        $('[data-slug="' + filter +'"]').trigger('click');
    }
})