import $ from 'jquery';
import ScrollAffix from 'scroll-affix';

$(document).ready(function () {
    let tabset = null;

    function initFilterList() {
        const filterList = $('.filter-list');

        if (!filterList.length > 0) {
            return;
        }

        const mq = window.matchMedia( "(max-width: 960px)" );

        if (mq.matches) {
            new ScrollAffix({
                el: filterList[0],
                offsetTop: document.body.classList.contains("twint") ? 94 : 70,
            });

            if (tabset === null) {
                tabset = filterList.scrollTabs({
                    left_arrow_size: 0,
                    right_arrow_size: 0,
                });
            }
        } else {
            if (tabset !== null) {
                tabset.destroy();
                tabset = null;
            }
        }
    }

    $(window).on('resize',function(){
        initFilterList();
    });

    initFilterList();

    $(document).on('click', '.filter', function (e) {
        e.preventDefault();
        const self = $(this);
        self.parents('.filter-list').find('[aria-checked]').attr('aria-checked', 'false');
        self.attr('aria-checked', !(self.attr('aria-checked') === 'true'));
    })
})