import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.min';

const lightbox = new PhotoSwipeLightbox({
    gallery: '.product-gallery',
    children: 'a',
    showHideAnimationType: 'none',
    zoomAnimationDuration: false,
    bgClickAction: 'close',
    zoom: false,
    clickToCloseNonZoomable: false,
    tapAction: '',
    loop: false,
    pswpModule: () => import('photoswipe')
});
lightbox.init();