import $ from 'jquery';
const { eguma } = window;
const INDICATOR_CLASS = ".product-card-wishlist-indicator";
const CACHE_KEY = "wishlistVtIds";


const updateWishlistCacheId = (id, action) => {
    console.log(id, action)
    if(action === "add"){
        const cache = getWishlistCache();
        setWishlistCache([...cache, id]);
    }

    if(action === "remove"){
        const cache = getWishlistCache();
        setWishlistCache(cache.filter(c => c !== id))
    }
}

const setWishlistCache = (vtIds) => {
    sessionStorage.setItem(CACHE_KEY, JSON.stringify(vtIds));
}

const getWishlistCache = () => {
    return JSON.parse(sessionStorage.getItem(CACHE_KEY));
}

const hasValidCache = () => {
    return sessionStorage.getItem(CACHE_KEY) !== null;
}

const toggleWishlist = (vtId, userId, onWishlist, element) => {
    const action = onWishlist ? "remove" : "add";

    $.ajax({
        url: `/${eguma.langId}/twint/wishlist/${action}`,
        method: "POST",
        data: { vtId: vtId, userId: userId },
        success: function () {
            $(element).children(".selected").first().fadeToggle()
            $(element).children(".not-selected").first().fadeToggle()
            element.dataset.isonuserswishlist = !onWishlist

            updateWishlistCacheId(vtId,action);
        }
    });
}

const updateDisplayElements = () => {
    const entries = getWishlistCache();
    const domIndicatorElements = $(INDICATOR_CLASS).toArray();
    entries?.forEach(entry => {
        const domElement = domIndicatorElements.find(element => element.dataset.vtid === entry);
        if (!domElement) {
            return; // for detail-page
        }
        const jQueryElement = $(domElement);
        jQueryElement.children(".selected").first().show();
        jQueryElement.children(".not-selected").first().hide();
        domElement.dataset.isonuserswishlist = true;
    });
}

// Re-register click handler on filterbar filter change
$(document).on('filter-loading-completed', '.filter', function (e) {
    e.preventDefault();
    setupOnClickHandler();
    updateDisplayElements();
});

const setupOnClickHandler = () => {
    $(INDICATOR_CLASS).unbind();
    $(INDICATOR_CLASS).click(function (e) {
        e.preventDefault();
        const { vtid, twintpluscustomerrelationuuid, isonuserswishlist } = e.currentTarget.dataset;
        const onWishlist = (isonuserswishlist.toLowerCase() === 'true'); // explicitly convert to boolean from string
        toggleWishlist(vtid, twintpluscustomerrelationuuid, onWishlist, this)
    });
}

$(document).ready(function () {
    if ($(INDICATOR_CLASS).length === 0) {
        return;
    }

    if (!hasValidCache()) {
        $.ajax({
            url: `/${eguma.langId}/twint/wishlist/get-vtids`,
            method: "GET",
            async: "false",
            success: function (data) {
                setWishlistCache(data.entries);
                updateDisplayElements();
            }
        });
    } else {
        updateDisplayElements();
    }
    setupOnClickHandler();
});
