import $ from 'jquery';
const {eguma} = window;

$(".region-selector").change(async function(e) {
    const target = '.redemption-points-container';
    const targetElement = $(target);
    e.preventDefault();
    $('.product-list-loader').fadeIn(20);
    $(".region-selector").css("pointer-events", "none");

    var url = new URL(window.location.href);
    url.searchParams.set("RegionCode", this.value);

    var res = await $.ajax({
      type: "POST",
      url,
      data: {},
      cache: false,
      contentType: false
    });
    if (res.success === false) {
        $(".region-selector").css("pointer-events", "all");
    }

    let targetResponse = $(target, res).children();
    if (targetResponse.length === 0) {
        targetResponse = "<div class='col'>Keine Angebote in dieser Region</div>";
    }

    targetElement.hide().html(targetResponse).fadeIn('slow');
    $('.product-list-loader').fadeOut();

    $(".region-selector").css("pointer-events", "all");
});