import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import $ from "jquery";

$(document).ready(function () {
  $(".intro-swiper").each(function () {
    const self = $(this);
    new Swiper(self[0], {
      modules: [Navigation, Pagination],
      loop: false,
      watchSlidesProgress: true, // https://github.com/nolimits4web/swiper/issues/5437
      // Navigation arrows
      navigation: {
        nextEl: self.find(".swiper-button-next")[0],
        prevEl: self.find(".swiper-button-prev")[0]
      },
      pagination: {
        el: self.find(".swiper-pagination")[0],
        type: "fraction"
      }
    });
  });

  $(".content-slider-swiper").each(function () {
    const self = $(this);
    new Swiper(self[0], {
      modules: [Navigation],
      loop: false,
      spaceBetween: 12,
      watchSlidesProgress: true, // https://github.com/nolimits4web/swiper/issues/5437
      breakpoints: {
        // breakpoint from 0 up
        0: {
          slidesPerView: document.body.classList.contains("twint") ? 2.2 : 1.2
        },
        500: {
          slidesPerView: 2.2
        },
        768: {
          slidesPerView: 3
        },
        1024: {
          slidesPerView: 4
        }
      },
      // Navigation arrows
      navigation: {
        nextEl: self
          .siblings(".swiper-navigation")
          .find(".swiper-button-next")[0],
        prevEl: self
          .siblings(".swiper-navigation")
          .find(".swiper-button-prev")[0]
      }
    });
  });

  $(".related-products-swiper").each(function () {
    const self = $(this);
    new Swiper(self[0], {
      modules: [Navigation],
      loop: false,
      spaceBetween: 12,
      watchSlidesProgress: true, // https://github.com/nolimits4web/swiper/issues/5437
      breakpoints: {
        // breakpoint from 0 up
        0: {
          slidesPerView: document.body.classList.contains("twint") ? 2.2 : 1.2
        },
        500: {
          slidesPerView: 2.2
        },
        768: {
          slidesPerView: 3
        },
        1024: {
          slidesPerView: 4
        }
      },
      // Navigation arrows
      navigation: {
        nextEl: self
          .siblings(".swiper-navigation")
          .find(".swiper-button-next")[0],
        prevEl: self
          .siblings(".swiper-navigation")
          .find(".swiper-button-prev")[0]
      }
    });
  });
});
